<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card
      elevation="24"
      :loading="loading"
    >
      <v-card-title v-if="people" class="">
        تعديل {{ people.name }}
      </v-card-title>
      <v-container>
        <v-row>
          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.name"
              v-max-length="200"
              :rules="rules.nameRules"
              label="اسم الشخص"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.phone_number"
              v-max-length="200"
              :rules="rules.phone_numberRules"
              label="رقم الهاتف"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.address"
              v-max-length="200"
              label="العنوان"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            v-if="isCitizen"
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.identification_number"
              v-max-length="200"
              label="الرقم التعريفي"
              type="number"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="6"
          >
            <v-text-field
              v-model="form.sequence"
              v-max-length="200"
              :rules="rules.sequenceRules"
              label="التسلسل"
              type="number"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="6"
          >
            <v-text-field
              v-model="form.page"
              v-max-length="200"
              :rules="rules.pageRules"
              label="الصفحة"
              type="number"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col
            v-if="!isCitizen"
            cols="6"
            md="6"
            sm="12"
          >
            <v-textarea
              v-model="form.dignitary_note"
              label="ملاحظات شخصية"
              outlined
              rows="2"
              hint="ملاحظات شخصية"
            ></v-textarea>
          </v-col>
          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <v-select
              v-model="form.active"
              :items="CheckActive"
              :rules="rules.activeRules"
              item-text="state"
              item-value="value"
              label="الحالة"
              outlined
            ></v-select>
          </v-col>
          <v-col
            v-if="!isCitizen"
            cols="4"
            md="6"
            sm="12"
          >
            <v-select
              v-model="form.enter_all_cars"
              :items="Enter_All_Cars"
              item-text="state"
              item-value="value"
              label=" دخول السيارات برفقته"
              outlined
            ></v-select>
          </v-col>
          <v-col
            v-if="form.enter_all_cars === 1"
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.cars_count"
              v-max-length="200"
              type="number"
              label="عدد العجلات المسموح بدخولها"
              outlined
              hint="عدد العجلات المسموح بدخولها"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <v-select
              v-model="form.level"
              :items="Levels"
              :rules="rules.levelRules"
              item-text="state"
              item-value="value"
              label="السماح بالدخول في الزيارات"
              outlined
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="form.identifier_id"
              :items="identifiers"
              item-text="name"
              item-value="id"
              label="المعرف"
              clearable
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-textarea
              v-model="form.identifier_type"
              label="طريقة التعريف"
              outlined
              rows="2"
              hint="طريقة التعريف"
            ></v-textarea>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="form.checkpoints"
              :items="checkpoints"
              item-text="name"
              multiple
              item-value="id"
              label="السيطرات"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="4"
            md="6"
            sm="12"
          >
            <date-picker
              v-model="form.expire_date"
              style="width: 100%;"
              class="mb-5"
              placeholder="تاريخ الانتهاء"
              value-type="format"
            ></date-picker>
          </v-col>
          <v-col
            :cols="imagePreview ? 9 : 12"
          >
            <v-file-input
              ref="file"
              v-model="form.image"
              type="file"
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              @change="fileChanged"
            ></v-file-input>
          </v-col>
          <v-col
            v-if="imagePreview"
            cols="3"
          >
            <v-avatar
              color=""
              class="v-avatar-light-bg primary--text mb-1 "
              size="100"
              rounded
            >
              <v-img
                :src="imagePreview"
              >
              </v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4"
            color="success"
            :disabled="!valid"
            @click="submit"
          >
            {{ $t('form.update') }}
          </v-btn>
          <v-btn
            color="error"
            :to="{name: isCitizen ? 'citizens.index' : 'peoples.index'}"
          >
            {{ $t('form.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-form>
</template>
<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ar-sa'
import DatePicker from 'vue2-datepicker'
import axios from '@axios'
import toast from '@/store/toast'

export default {
  components: { DatePicker },
  data: () => ({
    identifiers: [],
    valid: false,
    people: null,
    checkpoints: [],
    loading: true,
    imagePreview: null,
    form: {
      name: '',
      phone_number: '',
      sequence: '',
      expire_date: '',
      page: '',
      dignitary_note: '',
      active: '',
      address: '',
      enter_all_cars: '',
      cars_count: '',
      citizen: '',
      level: '',
      image: null,
      identifier_id: '',
      identifier_type: '',
      identification_number: '',
      checkpoints: [],
    },

    CheckActive: [
      { state: 'فعال', value: 1 },
      { state: 'غير فعال', value: 0 },
    ],
    Enter_All_Cars: [
      { state: 'يسمح', value: 1 },
      { state: 'لايسمح', value: 0 },
    ],
    Citizen: [
      { state: 'اصحاب المركبات', value: 1 },
      { state: 'سكنة المنطقة', value: 0 },
    ],
    Levels: [
      { state: 'كلا', value: 0, color: '#2196F3' },
      { state: 'مستوى اول', value: 1, color: '#3F51B5' },
      { state: 'مستوى ثاني', value: 2, color: '#673AB7' },
      { state: 'مستوى ثالث', value: 3, color: '#F50057' },
    ],

  }),
  computed: {
    isCitizen() {
      return this.people && this.people.citizen;
    },
    rules() {
      const rules = {};
      rules.nameRules = [
        v => !!v || this.$t('form.required', ['الاسم']),
      ];
      rules.phone_numberRules = [
        v => !v || /\d{11}/.test(v) || this.$t('form.pattern', ['رقم الهاتف']),
      ];

      rules.sequenceRules = [
        v => v !== null || this.$t('form.negative', ['التسلسل']),
      ];
      rules.pageRules = [
        v => v !== null || this.$t('form.negative', ['الصفحة']),
      ];
      rules.dignitary_noteRules = [
        v => !!v || this.$t('form.required', ['الملاحظة الشخصية']),
      ];
      rules.activeRules = [
        v => v !== null || this.$t('form.required', ['الحالة']),
        v => v !== '' || this.$t('form.required', ['الحالة'])];
      rules.enter_all_carsRules = [
        v => v !== null || this.$t('form.required', ['سماحية']),
        v => v !== '' || this.$t('form.required', ['سماحية']),
      ];
      rules.cars_count = this.form.enter_all_cars === 1 ? [
        v => v !== null || this.$t('form.required', ['عدد العجلات المسموح بدخولها']),
        v => v !== '' || this.$t('form.required', ['عدد العجلات المسموح بدخولها']),
      ] : [];
      rules.citizenRules = [
        v => v !== null || this.$t('form.required', ['النوع']),
        v => v !== '' || this.$t('form.required', ['النوع']),
      ];
      rules.checkpoints = !this.isCitizen ? [] : [
        v => v !== null || this.$t('form.required', ['السيطرات']),
        v => v !== '' || this.$t('form.required', ['السيطرات']),
      ];
      rules.levelRules = [
        v => v !== null || this.$t('form.required', ['المستوى']),
        v => v !== '' || this.$t('form.required', ['المستوى']),
      ];
      rules.identifier_idRules = [
        v => v !== null || this.$t('form.required', ['المعرف']),
        v => v !== '' || this.$t('form.required', ['المعرف']),
      ];

      return rules;
    },
  },
  async mounted() {
    await this.fetch();
    const response = await axios.get(`/peoples/${this.$route.params.id}`);
    const people = response.data.data;
    if (people.media && people.media.length) {
      this.imagePreview = people.media[0].original_url;
    }

    this.form = {
      name: people.name || '',
      phone_number: people.phone_number || '',
      sequence: people.sequence || '',
      page: people.page || '',
      dignitary_note: people.dignitary_note || '',
      active: people.active,
      address: people.address || '',
      enter_all_cars: people.enter_all_cars,
      cars_count: people.cars_count || '',
      identifier_type: people.identifier_type || '',
      level: people.level,
      expire_date: people.expire_date || '',
      identification_number: people.identification_number || '',
      identifier_id: people.identifier_id || '',
      checkpoints: people.checkpoints ? people.checkpoints.map(checkpoint => checkpoint.id) : [],
    };
    this.people = people;
    this.loading = false;
    this.$refs.form.validate();
  },
  methods: {
    async fetch() {
      const response = await axios.get('/identifiers');
      this.identifiers = response.data.data
      const check = await axios.get('/checkpoints')
      this.checkpoints = check.data.data
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append('image', this.form.image);
        formData.append('name', this.form.name);
        formData.append('phone_number', this.form.phone_number);
        formData.append('address', this.form.address);
        formData.append('expire_date', this.form.expire_date);
        formData.append('sequence', this.form.sequence);
        formData.append('identifier_type', this.form.identifier_type);
        formData.append('page', this.form.page);
        if (!this.isCitizen) {
          if (this.form.enter_all_cars) {
            formData.append('enter_all_cars', this.form.enter_all_cars);
          }
          formData.append('cars_count', this.form.cars_count);
          formData.append('dignitary_note', this.form.dignitary_note);
        } else {
          formData.append('identification_number', this.form.identification_number);
        }
        formData.append('active', this.form.active);
        formData.append('level', this.form.level);
        this.form.checkpoints.forEach(checkpoint => formData.append('checkpoints[]', checkpoint))
        if (this.form.identifier_id) {
          formData.append('identifier_id', this.form.identifier_id);
        }
        await axios.post(`/peoples/edit/${this.$route.params.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        await toast.dispatch('success', this.$t('form.success.create'))
        await this.$router.push({ name: this.isCitizen ? 'citizens.index' : 'peoples.index' })
      }
    },

    fileChanged() {
      if (!this.form.image) {
        this.imagePreview = null

        return null
      }
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
        address: '',
        phone_number: '',
        enter_all_cars: null,
        citizen: null,
        page: null,
        dignitary_note: null,
        identifier_id: null,
        expire_date: null,
        level: null,
        image: null,
        checkpoints: null,
      };
      this.imagePreview = null;
    },
  },

}
</script>
